import React from "react";
import styled from "styled-components";
import UnstyledLink from "./UnstyledLink";
import BaseContentBox from "./BaseContentBox";
import formatPublishedDate from "../../formatPublishedDate";

const StyledEpisodeBox = styled(BaseContentBox)`
  padding: 1.5em 2.5em;
  border-bottom: 8px solid #f5b275;
`;

const EpisodeTitle = styled.h4`
  font-weight: ${(props) => props.theme.fonts.weights.regular};
  color: hsl(0, 0%, 10%);
  margin-bottom: 0.5em;
`;

const EpisodePublishDate = styled.p`
  font-size: 14px;
  color: hsl(0, 0%, 46%);
  margin-top: 0;
  margin-bottom: 1.8em;
`;

const EpisodeDescription = styled.p`
  line-height: 1.8em;
  color: hsl(0, 0%, 22%);
`;

type EpisodeBoxProps = {
  title: string;
  slug: string;
  description: string;
  published: string;
};

const EpisodeBox: React.FC<EpisodeBoxProps> = ({
  title,
  slug,
  description,
  published,
}) => {
  return (
    <UnstyledLink to={`/episode/${slug}`}>
      <StyledEpisodeBox>
        <EpisodeTitle>{title}</EpisodeTitle>
        <EpisodePublishDate>
          {formatPublishedDate(published)}
        </EpisodePublishDate>
        <EpisodeDescription>{description}</EpisodeDescription>
      </StyledEpisodeBox>
    </UnstyledLink>
  );
};

export default EpisodeBox;
