import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EpisodeBox from "./components/common/EpisodeBox";
import EpisodesIcon from "./assets/svgs/icon-microphone.svg";
import PageWithContentBoxes from "./components/common/PageWithContentBoxes";

type Episode = {
  frontmatter: {
    title: string;
    description: string;
    published: string;
  };
  fields: {
    slug: string;
  };
};

type EpisodesResponse = {
  episodes: {
    nodes: Episode[];
  };
};

const Episodes: React.FC = () => {
  const data = useStaticQuery<EpisodesResponse>(graphql`
    {
      episodes: allMdx(
        filter: { slug: { glob: "episodes/*" } }
        sort: { fields: frontmatter___number, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            description
            published
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  return (
    <PageWithContentBoxes title="Episodes" icon={<EpisodesIcon />}>
      {data.episodes.nodes.map((episode) => (
        <EpisodeBox
          key={episode.fields.slug}
          title={episode.frontmatter.title}
          slug={episode.fields.slug}
          description={episode.frontmatter.description}
          published={episode.frontmatter.published}
        />
      ))}
    </PageWithContentBoxes>
  );
};

export default Episodes;
