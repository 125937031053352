import React from "react";
import Page from "../components/Page";
import Episodes from "../Episodes";

type EpisodesPageProps = {
  location?: {
    pathname?: string;
  };
};

const EpisodesPage: React.FC<EpisodesPageProps> = ({ location }) => {
  return (
    <Page title="Episodes" path={location?.pathname}>
      <Episodes />
    </Page>
  );
};

export default EpisodesPage;
